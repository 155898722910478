<template>
  <div class="white--text">
    <v-row>
      <v-col cols="12" md="9">
        <div class="text-h2 text-md-h1 font-weight-bold mb-12">
          Crea tu perfil en Stripe para recibir tus pagos de forma segura
        </div>
        <div class="mr-md-6">
          <div
            class="mb-5 text-body-1 text-md-h4 font-weight-regular"
            style="line-height: normal"
          >
            Stripe posibilita los pagos para minoristas que operan en Internet y
            en persona, empresas que trabajan con suscripciones, plataformas de
            software y marketplaces y mucho más.
          </div>
          <div class="mb-10 text-body-1 text-md-h4 font-weight-regular">
            Conoce más sobre
            <a
              href="https://stripe.com/es/payments"
              target="_blank"
              rel="noopener noreferrer"
              style="color: inherit"
            >
              Stripe
            </a>
          </div>
          <div
            class="font-italic mb-16 pb-12 text-body-1 text-md-h4 font-weight-regular"
          >
            Para aceptar solicitudes de tus servicios deberás crear tu perfil de
            Stripe.
          </div>
        </div>
        <v-row class="mb-10">
          <v-col cols="12" sm="11">
            <div
              class="d-flex flex-column flex-sm-row justify-end align-center"
            >
              <Button
                v-if="!$vuetify.breakpoint.xs"
                text="Omitir"
                outlined
                color="white"
                :block="$vuetify.breakpoint.xs"
                :horizontal-padding="6"
                :disabled="loading"
                class="mr-sm-3"
                aria-label="Omitir registro de cuenta Stripe e ir a mis productos"
                url="ProviderProducts"
              />
              <router-link
                v-else
                class="text-decoration-none d-flex align-center mt-6 mt-sm-0 order-2"
                style="color: inherit"
                :style="loading && 'pointer-events: none; cursor: default;'"
                aria-label="Omitir registro de cuenta Stripe e ir a mis productos"
                :to="{ name: 'ProviderProducts' }"
              >
                <span
                  class="text-h6 font-weight-bold white--text text-decoration-underline"
                >
                  Omitir
                </span>
              </router-link>
              <Button
                text="Crear Perfil"
                :disabled="loading"
                :block="$vuetify.breakpoint.xs"
                aria-label="Crear perfil"
                @event="redirectStripe()"
              />
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <ModalLoading :dialog="loading" />
  </div>
</template>

<script>
import Button from "@/components/Shared/Button.vue";
import ModalLoading from "@/components/Shared/Modal/ModalLoading.vue";
import { mapState } from "vuex";
import { useQuery } from "@/graphql/index";
import { GET_STRIPE_URL_REGISTER } from "@/graphql/queries";

export default {
  name: "RegisterStripe",
  components: {
    Button,
    ModalLoading,
  },
  data() {
    return {
      loading: false,
      id: null,
      stripeId: null,
    };
  },
  computed: mapState({
    provider(state) {
      return state.provider;
    },
  }),
  methods: {
    handleAlert({ type, message }) {
      this.$store.dispatch("handleAlert", {
        type: type,
        message: message,
      });
    },
    async redirectStripe() {
      this.loading = true;
      const { data, errors } = await useQuery(GET_STRIPE_URL_REGISTER, {
        stripe_id: this.provider.stripeConnectedAccount,
        return_url:
          process.env.VUE_APP_STRIPE_URL +
          "proveedor/registro/" +
          this.provider.id +
          "/stripe-registro-exitoso",
        refresh_url: process.env.VUE_APP_STRIPE_URL + "proveedor/servicios",
      });
      if (data) {
        window.location.replace(data.getStripeUrlRegister.url);
      } else if (errors) {
        this.handleAlert({
          type: "error",
          message: "Error durante la conexión con Stripe. Vuelve a intentarlo.",
        });
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.btn-font {
  font-size: 1.05rem !important;
}
</style>
