<template>
  <div class="white--text">
    <div
      class="text-center text-md-start text-h2 text-sm-h1 font-weight-bold mb-10 mb-sm-14"
    >
      Registra tu empresa
    </div>
    <v-row>
      <v-col cols="12" md="7">
        <v-text-field
          v-model="providerData.workName"
          outlined
          dark
          class="input-background rounded-lg v-input--is-focused"
          :rules="nameRules"
          validate-on-blur
          maxlength="60"
          counter="60"
          autocomplete="null"
        >
          <template v-slot:label>
            <div class="white--text">Nombre de la empresa</div>
          </template>
        </v-text-field>

        <v-text-field
          v-model="providerData.phoneNum"
          outlined
          dark
          :counter="10"
          maxlength="10"
          class="input-background rounded-lg v-input--is-focused"
          :rules="phoneRules"
          validate-on-blur
          autocomplete="null"
        >
          <template v-slot:label>
            <div class="white--text">Teléfono</div>
          </template>
        </v-text-field>

        <v-text-field
          v-model="providerData.email"
          outlined
          dark
          class="input-background rounded-lg v-input--is-focused"
          validate-on-blur
          autocomplete="null"
          maxlength="100"
          :rules="emailRules"
        >
          <template v-slot:label>
            <div class="white--text">Correo electrónico</div>
          </template>
        </v-text-field>

        <v-autocomplete
          v-model="providerData.workType"
          :items="categories"
          auto-select-first
          outlined
          dark
          color="white"
          item-text="name"
          item-color="primary"
          no-data-text="Giro de empresa no encontrado"
          :menu-props="{
            bottom: true,
            closeOnContentClick: true,
            allowOverflow: true,
            offsetY: true,
            rounded: 'md',
            transition: 'slide-y-transition',
          }"
          autocomplete="null"
          validate-on-blur
          :rules="optionRules"
          class="input-background rounded-lg v-input--is-focused white--text"
        >
          <template v-slot:label>
            <div class="white--text">Giro de la empresa</div>
          </template>
          <template v-slot:append>
            <span class="v-input__icon--append">
              <v-icon size="25" color="white" class="v-input__icon--append">
                mdi-chevron-down
              </v-icon>
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="5" xl="4">
        <div class="d-flex flex-column align-center mt-md-n12">
          <div
            class="text-h3 text-sm-h2 text-center"
            :class="!$vuetify.breakpoint.xs && 'font-weight-bold'"
          >
            Sube tu foto de perfil empresarial
          </div>
          <Mult-image-upload
            :max="1"
            :max-mbs="5"
            upload-msg="Haz click o arrastra una imagen al recuadro"
            max-error="Solamente puede subir hasta 1 imagen."
            file-error="Solamente se aceptan imágenes (.png o .jpg)"
            color-borders="white"
            :large-img="true"
            @imagesChanged="updateProfilePic"
          />
          <div v-if="noPhoto" class="text-body-1 text-center red--text">
            Debes subir una foto
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MultImageUpload from "@/components/Shared/MultImageUpload.vue";
import {
  nameRules,
  cityRules,
  emailRules,
  phoneRules,
  optionRules,
} from "@/Utils/rules";
import { mapState } from "vuex";

export default {
  components: {
    MultImageUpload,
  },
  props: {
    providerData: {
      type: Object,
      require: true,
      default: () => ({}),
    },
    noPhoto: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["profilePicUpdated"],
  data() {
    return {
      nameRules: nameRules,
      cityRules: cityRules,
      emailRules: emailRules,
      phoneRules: phoneRules,
      optionRules: optionRules,
    };
  },
  computed: {
    ...mapState(["categories"]),
  },
  methods: {
    updateProfilePic(images) {
      this.$emit("profilePicUpdated", images);
    },
  },
};
</script>

<style lang="scss">
.input-background.v-text-field > .v-input__control > .v-input__slot {
  background-color: rgba(255, 255, 255, 0.3) !important;
}
</style>
