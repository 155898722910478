<template>
  <div>
    <div v-if="section == 'provider'" class="fill-height purple-gradient">
      <Navbar page="register" />
      <v-container v-if="isProvider || providerLoading">
        <InlineLoading />
      </v-container>
      <div v-else>
        <v-progress-linear
          v-show="page !== 0 && page !== 4"
          :value="progressValue"
          color="secondary"
          background-color="white"
          :height="$vuetify.breakpoint.xs ? 22 : 13"
          :style="
            $vuetify.breakpoint.xs
              ? 'position: fixed; bottom: -1px; z-index: 2;'
              : ''
          "
        >
        </v-progress-linear>
        <v-container class="pt-14">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="mx-8 mx-sm-12 mx-md-16"
          >
            <div v-show="page == 0" class="mt-md-10">
              <v-form ref="registerCompanyForm" v-model="valid" lazy-validation>
                <RegisterCompany
                  :provider-data="providerData"
                  :no-photo="noPhoto"
                  @profilePicUpdated="updateProfilePic"
                />
              </v-form>
            </div>
            <div v-show="page == 1" class="mt-md-3">
              <v-form
                ref="verifyCompanyProfileForm"
                v-model="valid"
                lazy-validation
              >
                <VerifyCompanyProfile
                  :provider-data="providerData"
                  :no-id-card="noIdCard"
                  @idCardUpdated="updateIdCard"
                  @providerCityUpdated="updateProviderCity"
                  @providerStateUpdated="updateProviderState"
                />
              </v-form>
            </div>
            <div v-show="page == 2" class="mt-md-3">
              <FirstImpression
                :no-images="noImages"
                @imagesUpdated="updateImages"
              />
            </div>
            <div v-show="page == 3" class="mt-md-3">
              <v-form ref="socialMediaForm" v-model="valid" lazy-validation>
                <SocialMedia :provider-data="providerData" />
              </v-form>
            </div>
            <div v-if="page == 4" class="mt-md-10">
              <RegisterStripe />
            </div>
            <v-row v-if="page !== 4" class="mt-12 mt-md-6 mb-8">
              <v-col
                cols="12"
                :md="page == 0 ? 7 : page == 1 || page == 2 ? 11 : 12"
              >
                <div
                  class="d-flex flex-column flex-sm-row justify-space-between align-center"
                >
                  <div v-if="page == 0" class="mt-6 mt-sm-0 order-2 order-sm-1">
                    <v-hover v-slot="{ hover }">
                      <a
                        class="text-decoration-none d-flex align-center"
                        style="color: inherit"
                        @click="cancelRegister"
                      >
                        <v-icon
                          class="mr-n1"
                          color="white"
                          :size="$vuetify.breakpoint.xs ? 30 : 40"
                        >
                          mdi-chevron-left
                        </v-icon>
                        <span
                          class="text-h4 font-weight-bold white--text"
                          :class="hover && 'text-decoration-underline'"
                        >
                          Cancelar registro
                        </span>
                      </a>
                    </v-hover>
                  </div>
                  <div
                    v-if="page !== 0"
                    class="mt-6 mt-sm-0 order-2 order-sm-1"
                  >
                    <v-hover v-slot="{ hover }">
                      <a
                        class="text-decoration-none d-flex align-center ml-n3"
                        style="color: inherit"
                        :style="
                          loading && 'pointer-events: none; cursor: default;'
                        "
                        @click="previousPage"
                      >
                        <v-icon
                          class="mr-n1"
                          color="white"
                          :size="$vuetify.breakpoint.xs ? 30 : 40"
                        >
                          mdi-chevron-left
                        </v-icon>
                        <span
                          class="text-h4 font-weight-bold white--text"
                          :class="hover && 'text-decoration-underline'"
                        >
                          Atrás
                        </span>
                      </a>
                    </v-hover>
                  </div>
                  <Button
                    v-if="page !== 3"
                    text="Continuar"
                    :block="$vuetify.breakpoint.xs"
                    class="opacity-button order-1 order-sm-2"
                    aria-label="Continuar al siguiente paso del registro"
                    @event="validate()"
                  />
                  <Button
                    v-if="page === 3"
                    text="Finalizar"
                    :block="$vuetify.breakpoint.xs"
                    :disabled="loading"
                    class="opacity-button order-1 order-sm-2"
                    aria-label="Finalizar registro"
                    @event="validate()"
                  />
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </div>
      <ModalLoading :dialog="loading" />
    </div>
    <div v-else-if="section == 'product'">
      <CreateProduct
        is-first-product
        register-product-title="¡Ya tienes tu cuenta de proveedor!"
        register-product-subtitle="Vamos a dar de alta tu primer producto Zaturna"
        @redirectRegisterStripe="redirectRegisterStripe"
      />
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Provider/Shared/Navbar.vue";
import RegisterCompany from "@/components/Provider/Register/RegisterCompany.vue";
import VerifyCompanyProfile from "@/components/Provider/Register/VerifyCompanyProfile.vue";
import FirstImpression from "@/components/Provider/Register/FirstImpression.vue";
import SocialMedia from "@/components/Provider/Register/SocialMedia.vue";
import CreateProduct from "@/views/Provider/CreateProduct.vue";
import RegisterStripe from "@/components/Provider/Register/RegisterStripe.vue";
import Button from "@/components/Shared/Button.vue";
import ModalLoading from "@/components/Shared/Modal/ModalLoading.vue";
import InlineLoading from "@/components/Shared/InlineLoading.vue";
import { mapState } from "vuex";

export default {
  name: "ProviderRegister",
  components: {
    Navbar,
    RegisterCompany,
    VerifyCompanyProfile,
    FirstImpression,
    SocialMedia,
    CreateProduct,
    RegisterStripe,
    Button,
    ModalLoading,
    InlineLoading,
  },
  data() {
    return {
      section: "provider",
      page: 0,
      value: 100 / 4,
      progressValue: 0,
      providerData: {
        providerImages: { imagesBase64: [] },
        profilePicture: [],
      },
      idCard: [],
      noPhoto: false,
      noIdCard: false,
      noImages: false,
      valid: true,
      loading: false,
      isProvider: false,
      providerLoading: true,
      loadBuffer: 2,
    };
  },
  computed: mapState({
    provider(state) {
      if (state.provider) {
        return state.provider;
      } else {
        return false;
      }
    },
  }),
  watch: {
    provider: {
      async handler(provider) {
        if (provider && provider.id && this.page === 0) {
          this.isProvider = true;
        } else {
          this.isProvider = false;
        }
        this.providerLoading = false;
        provider &&
          this.page === 0 &&
          this.$router.push({ name: "UserMainPage" });
      },
    },
    loadBuffer: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.loadBuffer--;
          }, 1000);
        }
        if (this.loadBuffer <= 1) {
          this.providerLoading = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    nextPage() {
      this.page++;
      this.scrollToTop();
      this.progressValue += this.value;
    },
    previousPage() {
      this.page--;
      this.scrollToTop();
      this.progressValue -= this.value;
    },
    async validate() {
      if (this.page == 0) {
        this.noPhoto = this.providerData.profilePicture.length == 0;
        if (this.$refs.registerCompanyForm.validate() && !this.noPhoto) {
          this.nextPage();
        }
      } else if (this.page == 1) {
        this.noIdCard = this.idCard.length == 0;
        if (this.$refs.verifyCompanyProfileForm.validate() && !this.noIdCard) {
          this.nextPage();
        }
      } else if (this.page == 2) {
        this.noImages =
          this.providerData.providerImages.imagesBase64.length == 0;
        if (!this.noImages) {
          this.nextPage();
        }
      } else if (this.page == 3) {
        if (this.$refs.socialMediaForm.validate()) {
          await this.registerProvider();
        }
      }
    },
    async registerProvider() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let data = await this.$store.dispatch("createProvider", {
          provider: this.providerData,
          idCard: this.idCard,
        });
        if (data) {
          this.section = "product";
        }
        this.loading = false;
      }
    },
    cancelRegister() {
      this.$router.push({ name: "UserMainPage" });
    },
    updateProfilePic(images) {
      this.providerData.profilePicture = images.images;
      this.noPhoto = this.providerData.profilePicture.length == 0;
    },
    updateProviderState(state) {
      this.providerData.stateOriginWork = state;
      this.providerData.cityOriginWork = "";
    },
    updateProviderCity(city) {
      this.providerData.cityOriginWork = city;
    },
    updateIdCard(images) {
      this.idCard = images.images;
      this.noIdCard = this.idCard.length == 0;
    },
    updateImages(images) {
      this.noImages = images.imagesBase64.length == 0;
      this.providerData.providerImages = images;
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    redirectRegisterStripe() {
      this.section = "provider";
      this.nextPage();
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-text-field--outlined fieldset {
  color: white !important;
}
::v-deep .v-select__selection,
::v-deep .v-select__selection--comma,
::v-deep .v-select.v-text-field input {
  color: white !important;
}
.purple-gradient {
  min-height: 100vh;
  background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0) 20%,
      rgba(98, 37, 130, 1) 100%
    ),
    url("../../assets/img/fondo_proveedor.png") no-repeat center center !important;
  background-size: cover !important;
}
.opacity-button.v-btn--active::before {
  opacity: 0;
}
</style>
