<template>
  <div class="white--text">
    <div class="text-center text-md-start text-h2 text-sm-h1 mb-10 mb-sm-16">
      Tu primera impresión con los clientes
    </div>

    <div
      class="d-flex flex-column align-center align-md-start text-center text-md-start"
    >
      <div
        class="text-h4 text-sm-h3 mb-2"
        :class="!$vuetify.breakpoint.xs && 'font-weight-bold'"
      >
        Sube tus mejores fotos para mostrarlas en tu perfil
      </div>

      <!-- Aun falta guardar las imagenes del proovedor al momento de subirlas-->
      <MultImageUpload
        class="d-flex justify-center justify-md-start"
        :max="20"
        :max-mbs="10"
        upload-msg="Haz click o arrastra una imagen al recuadro (max 20 imágenes)"
        max-error="Solamente puede subir hasta 20 imágenes."
        file-error="Solamente se aceptan imágenes (.png o .jpg)"
        color-borders="white"
        @imagesChanged="updateImages"
      />
      <div v-if="noImages" class="px-3 red--text">
        Debes subir al menos una foto
      </div>
    </div>
  </div>
</template>

<script>
import MultImageUpload from "@/components/Shared/MultImageUpload.vue";
export default {
  name: "FirstImpression",
  components: {
    MultImageUpload,
  },
  props: {
    noImages: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["imagesUpdated"],
  methods: {
    updateImages(images) {
      this.$emit("imagesUpdated", images);
    },
  },
};
</script>
