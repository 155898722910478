<template>
  <div class="white--text">
    <div
      class="text-center text-md-start text-h2 text-sm-h1 font-weight-bold mb-10 mb-sm-16"
    >
      Verifica tu perfil de empresa
    </div>
    <v-row>
      <v-col cols="12" md="12">
        <div class="d-flex flex-column">
          <div
            class="text-h4 text-sm-h3 text-center text-md-start"
            :class="!$vuetify.breakpoint.xs && 'font-weight-bold'"
          >
            Sube una identificación oficial del patrón
          </div>
          <div class="align-self-md-start mx-md-13">
            <MultImageUpload
              :max="1"
              :max-mbs="5"
              upload-msg="Haz click o arrastra una imagen al recuadro"
              max-error="Solamente puedes subir hasta 1 imagen."
              file-error="Solamente se aceptan imágenes (.png o .jpg)"
              color-borders="white"
              @imagesChanged="updateIdCard"
            />
            <div v-if="noIdCard" class="text-center red--text">
              Debes subir una foto
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="11">
        <v-row>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <div
              class="text-h4 text-sm-h3 mb-2"
              :class="!$vuetify.breakpoint.xs && 'font-weight-bold'"
            >
              RFC
            </div>
            <v-text-field
              v-model="providerData.rfc"
              dark
              outlined
              single-line
              maxlength="13"
              class="input-background rounded-lg v-input--is-focused code"
              :rules="rfcProviderRules"
              validate-on-blur
              autocomplete="null"
              @keyup="uppercase"
            >
              <template v-slot:label>
                <span class="white--text">RFC</span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <div
              class="text-h4 text-sm-h3 mb-2"
              :class="!$vuetify.breakpoint.xs && 'font-weight-bold'"
            >
              Estado
            </div>
            <v-autocomplete
              v-model="state"
              dark
              outlined
              auto-select-first
              single-line
              item-color="primary"
              :items="states"
              class="input-background rounded-lg v-input--is-focused"
              :rules="stateRulesSelect"
              :menu-props="{
                bottom: true,
                closeOnContentClick: true,
                allowOverflow: true,
                offsetY: true,
                rounded: 'md',
                transition: 'slide-y-transition',
              }"
              no-data-text="Estado no encontrado"
              autocomplete="null"
              @change="providerStateUpdated"
            >
              <template v-slot:label>
                <span class="white--text">Estado</span>
              </template>
              <template v-slot:append>
                <span class="v-input__icon--append">
                  <v-icon size="25" color="white" class="v-input__icon--append">
                    mdi-chevron-down
                  </v-icon>
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <div
              class="text-h4 text-sm-h3 mb-2"
              :class="!$vuetify.breakpoint.xs && 'font-weight-bold'"
            >
              Ciudad
            </div>
            <v-autocomplete
              v-model="city"
              dark
              auto-select-first
              outlined
              single-line
              item-color="primary"
              :items="citySelect"
              required
              class="input-background rounded-lg v-input--is-focused"
              validate-on-blur
              :menu-props="{
                bottom: true,
                closeOnContentClick: true,
                allowOverflow: true,
                offsetY: true,
                rounded: 'md',
                transition: 'slide-y-transition',
              }"
              :no-data-text="
                state ? 'Ciudad no encontrada' : 'Seleccione un estado'
              "
              :rules="cityRulesSelect"
              @change="providerCityUpdated"
            >
              <template v-slot:label>
                <span class="white--text">Ciudad</span>
              </template>
              <template v-slot:append>
                <span class="v-input__icon--append">
                  <v-icon size="25" color="white" class="v-input__icon--append">
                    mdi-chevron-down
                  </v-icon>
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <div
              class="text-h4 text-sm-h3 mb-2"
              :class="!$vuetify.breakpoint.xs && 'font-weight-bold'"
            >
              Calle
            </div>
            <v-text-field
              v-model="providerData.address"
              dark
              outlined
              single-line
              class="input-background rounded-lg v-input--is-focused"
              validate-on-blur
              autocomplete="null"
              maxlength="255"
              :rules="streetRules"
            >
              <template v-slot:label>
                <span class="white--text">Calle</span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <div
              class="text-h6 text-sm-h5 mb-2"
              :class="!$vuetify.breakpoint.xs && 'font-weight-bold'"
            >
              Colonia
            </div>
            <v-text-field
              v-model="providerData.blockName"
              dark
              outlined
              single-line
              class="input-background rounded-lg v-input--is-focused"
              validate-on-blur
              autocomplete="null"
              maxlength="255"
              :rules="neighborRules"
            >
              <template v-slot:label>
                <span class="white--text">Colonia</span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <div
              class="text-h4 text-sm-h3 mb-2"
              :class="!$vuetify.breakpoint.xs && 'font-weight-bold'"
            >
              Código postal
            </div>
            <v-text-field
              v-model="providerData.postalCode"
              dark
              outlined
              single-line
              :counter="5"
              maxlength="5"
              class="input-background rounded-lg v-input--is-focused"
              :rules="postalCodeRules"
              validate-on-blur
              autocomplete="null"
            >
              <template v-slot:label>
                <span class="white--text">Código postal</span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <div
              class="text-h4 text-sm-h3 mb-2"
              :class="!$vuetify.breakpoint.xs && 'font-weight-bold'"
            >
              Número exterior
            </div>
            <v-text-field
              v-model="providerData.externalNumber"
              dark
              outlined
              single-line
              class="input-background rounded-lg v-input--is-focused"
              maxlength="10"
              :rules="externalAddressNumberRules"
              validate-on-blur
              autocomplete="null"
            >
              <template v-slot:label>
                <span class="white--text">Número exterior</span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <div
              class="text-h4 text-sm-h3 mb-2"
              :class="!$vuetify.breakpoint.xs && 'font-weight-bold'"
            >
              Número interior
            </div>
            <v-text-field
              v-model="providerData.internalNumber"
              dark
              outlined
              single-line
              class="input-background rounded-lg v-input--is-focused"
              maxlength="10"
              :rules="optionalInternalAddressNumberRules"
              validate-on-blur
              autocomplete="null"
            >
              <template v-slot:label>
                <span class="white--text">Número interior</span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import MultImageUpload from "@/components/Shared/MultImageUpload.vue";
import {
  rfcProviderRules,
  stateRulesSelect,
  cityRulesSelect,
  streetRules,
  neighborRules,
  externalAddressNumberRules,
  optionalInternalAddressNumberRules,
  postalCodeRules,
} from "@/Utils/rules";
import { states, getCitiesByState } from "@/Utils/locations.js";
export default {
  components: {
    MultImageUpload,
  },
  props: {
    providerData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    noIdCard: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["idCardUpdated", "providerCityUpdated", "providerCityUpdated"],
  data() {
    return {
      state: "",
      city: "",
      states: states,
      rfcProviderRules: rfcProviderRules,
      stateRulesSelect: stateRulesSelect,
      cityRulesSelect: cityRulesSelect,
      streetRules: streetRules,
      neighborRules: neighborRules,
      externalAddressNumberRules: externalAddressNumberRules,
      optionalInternalAddressNumberRules: optionalInternalAddressNumberRules,
      postalCodeRules: postalCodeRules,
    };
  },
  computed: {
    citySelect() {
      return getCitiesByState(this.state);
    },
  },
  methods: {
    providerStateUpdated() {
      this.city = "";
      this.$emit("providerStateUpdated", this.state);
    },
    providerCityUpdated() {
      this.$emit("providerCityUpdated", this.city);
    },
    updateIdCard(images) {
      this.$emit("idCardUpdated", images);
    },
    uppercase() {
      this.providerData.rfc = this.providerData.rfc.toUpperCase();
    },
  },
};
</script>

<style lang="scss" scoped>
.input-background.v-text-field > .v-input__control > .v-input__slot {
  background-color: rgba(255, 255, 255, 0.3) !important;
}
</style>
