<template>
  <div class="white--text">
    <div
      class="text-center text-md-start text-h2 text-sm-h1 font-weight-bold mb-10 mb-sm-14"
    >
      Date a conocer con los clientes
    </div>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <div
          class="text-h4 text-sm-h3 mb-2"
          :class="!$vuetify.breakpoint.xs && 'font-weight-bold'"
        >
          Página de Facebook
        </div>
        <v-text-field
          v-model="providerData.facebookPage"
          dark
          outlined
          single-line
          class="input-background rounded-lg v-input--is-focused"
          maxlength="255"
          :rules="fillInput"
          autocomplete="null"
          prefix="/"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <div
          class="text-h4 text-sm-h3 mb-2"
          :class="!$vuetify.breakpoint.xs && 'font-weight-bold'"
        >
          Instagram
        </div>
        <v-text-field
          v-model="providerData.instagramPage"
          dark
          outlined
          single-line
          class="input-background rounded-lg v-input--is-focused"
          maxlength="255"
          :rules="fillInput"
          autocomplete="null"
          prefix="@"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <div
          class="text-h4 text-sm-h3 mb-2"
          :class="!$vuetify.breakpoint.xs && 'font-weight-bold'"
        >
          Proporciona una descripción general de tu empresa.
        </div>
        <v-textarea
          v-model="providerData.description"
          dark
          outlined
          flat
          dense
          no-resize
          auto-grow
          :rows="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 6 : 7"
          class="input-background rounded-lg v-input--is-focused"
          maxlength="1200"
          counter="1200"
          :rules="fillInput"
          autocomplete="null"
        >
        </v-textarea>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { fillInput } from "@/Utils/rules";

export default {
  props: {
    providerData: {
      type: Object,
      require: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      fillInput: fillInput,
    };
  },
};
</script>

<style lang="scss">
.v-text-field__prefix {
  color: white !important;
}
.input-background.v-text-field > .v-input__control > .v-input__slot {
  background-color: rgba(255, 255, 255, 0.3) !important;
}
</style>
